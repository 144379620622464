/*
 * This file is part of the Sylius package.
 *
 * (c) Paweł Jędrzejewski
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/* eslint-env browser */
let productOptionsLoading = false;

const handleProductOptionsChange = function handleProductOptionsChange() {

  document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]').forEach((item) => {
    item.addEventListener('change', (event) => {
      if (document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]').length === 2) {
        disableIncompatibleVariants(event.target);
      }
      else if (document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]').length > 2) {
        stepByStepProductOptions(event.target);
      }
      let selector = '';
      event.target.closest('#sylius-product-adding-to-cart').querySelectorAll('select[data-option]').forEach((element) => {
        const select = element;
        const option = select[select.selectedIndex].value;
        selector += `[data-${select.getAttribute('data-option')}="${option}"]`;
      });

      const priceElement = document.querySelector('#sylius-variants-pricing').querySelector(selector);
      let price = '';
      let originalPrice = '';
      let percentage = '';
      if (priceElement !== null) {
        price = priceElement.getAttribute('data-value');
        originalPrice = priceElement.getAttribute('data-original-value');
        percentage = priceElement.getAttribute('data-percentage');
      }

      if (price !== '') {
        document.querySelector('[data-js-product-price]').innerHTML = price;
        document.querySelector('button[type=submit]').removeAttribute('disabled');
      } else {
        document.querySelector('[data-js-product-price]').innerHTML = document.querySelector('#sylius-variants-pricing').getAttribute('data-unavailable-text');
        document.querySelector('button[type=submit]').setAttribute('disabled', 'disabled');
      }

      if(originalPrice !== ''){
        document.querySelector('[data-js-product-original-price]').innerHTML = originalPrice;

      }else {
        document.querySelector('[data-js-product-original-price]').innerHTML = '';
      }

      if(percentage !== null){

        document.querySelector('[data-js-product-percentage]').innerHTML = percentage + '%';
        document.querySelector('.picto-promo').style.display = 'inline-block';

      }else{
        if(document.querySelector('.picto-promo')){
          document.querySelector('.picto-promo').style.display = 'none';
        }
        if(document.querySelector('[data-js-product-percentage]')){
          document.querySelector('[data-js-product-percentage]').innerHTML = '';
        }
      }

      const detailsElement= document.querySelector('#sylius-variants-details').querySelector(selector);
      let variantDescription = '';
      let tracked = '';
      let onHand = '';
      let variantId = '';
      let restockingDate = '';
      if (detailsElement !== null) {
        variantDescription = detailsElement.getAttribute('data-description');
        tracked = detailsElement.getAttribute('data-tracked');
        onHand = detailsElement.getAttribute('data-onHand');
        variantId = detailsElement.getAttribute('data-variantId');
        restockingDate = detailsElement.getAttribute('data-restockingDate');
        $('#enStock').removeClass("d-none");
        $('#horsStock').removeClass("d-none");
        $('#restockingDate').removeClass("d-none");
        $('#restockingDateValue').html(restockingDate);
        if (restockingDate == '')
          {
            $('#restockingDate').addClass("d-none");
          }
      }

      if((tracked == 1 && onHand < 1)  && (!productOptionsLoading)){
        $('#enStock').hide();
        $('.btn-panier-content').hide();
        $('#horsStock').show();
        if (restockingDate != '')
        {
          $('#restockingDate').show();
        }
        $('#availability-notifier').removeClass("d-none");
        document.forms['app_availability_notifier'].action="/shop/availability_notifier/add-availability-notifier?productVariantId="+variantId+"&template=@SyliusShop/Product/Show/_availability_notifier.html.twig";
      }else{
        $('#enStock').show()
        $('#horsStock').hide();
        $('#restockingDate').hide();
        $('#variantQte').innerHTML=onHand;
        $('.btn-panier-content').show();

        $('#availability-notifier').addClass("d-none");
      }
    });
  });
};

// VARIANTS INCOMPATIBLES (dans le cas de 2 product options)
const disableIncompatibleVariants = (select) => {
  let selectValue = select.value;
  let selector = `[data-${select.getAttribute('data-option')}="${selectValue}"]`;
  select.closest('#sylius-product-adding-to-cart').querySelectorAll('select[data-option]').forEach((element) => { // tous les select (de product option)

    if (select !== element) { // faire le test sur tous les select sauf sur celui sur lequel est l'événement
      let firstValid = null;
      let changeValue = false;
      element.querySelectorAll('option').forEach(option => {
        let newSelector = selector;
        newSelector += `[data-${element.getAttribute('data-option')}="${option.value}"]`;
        // rechercher le variant qui correspond à cette combinaison
        let variantElement = document.querySelector('#sylius-variants-pricing').querySelector(newSelector);
        if (variantElement) {
          option.style.display = "block";
          if (!firstValid) {
            firstValid = option;
          }
        }
        else { // le variant n'existe pas
          option.style.display = "none";
          if (element.value === option.value) {
            changeValue = true;
          }
        }

      })
      // remplacer la valeur affichée si celle qui l'était n'est plus disponible
      if (changeValue) {
        firstValid.setAttribute("selected", true)
        element.value = firstValid.value;
      }
    }
  });
}

// VARIANTS INCOMPATIBLES (à partir de 3 product options)
const stepByStepProductOptions = (currentSelect, onPageLoaded = false) => {
  productOptionsLoading = true;
  let index = null;
  let chooseText = document.getElementById('sylius-variants-pricing').dataset.chooseOptionText;
  for (let i = 0; i < document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]').length; i++) {
    let select = document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]')[i];

    // find index | trouver l'index du select en question
    if (select === currentSelect) {
      index = i;
    }

    // greater indexes should be disabled | tous les select avec un index supérieur doivent être disabled
    if (typeof index != null && index != null && i > index) {
      deselectAllOptions(select);
      select.setAttribute("disabled", true)
    }

    // create if not exists a placeholder to force to choose in the list | mettre un placeholder pour obliger le client à choisir parmi la liste, s'il n'existe pas déjà
    let placeholder = null;
    select.querySelectorAll('option').forEach(option => {
      if (option.value === "") {
        placeholder = option;
      }
    })
    if (!placeholder) {
      placeholder = document.createElement('option');
    }
    placeholder.value = "";
    if (select === currentSelect) {
      placeholder.textContent = chooseText;
      if (onPageLoaded) {
        placeholder.setAttribute("selected", true)
      }
    }
    else {
      placeholder.setAttribute("selected", true)
    }
    placeholder.setAttribute("disabled", true)
    select.prepend(placeholder);
  }

  // able next select once the choice is made | une fois que le choix est fait sur ce select, rendre disponible le select suivant etc.
  if (currentSelect.value && currentSelect.value !== "") {
    document.getElementById('step-by-step-info-msg').style.display = "block";
    document.getElementById('add-to-cart-btn').setAttribute("disabled", true);
    let nextSelect = document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]')[index + 1];
    if (nextSelect) {
      deselectAllOptions(nextSelect);
      let nextSelectPlaceholder = null;
      nextSelect.removeAttribute("disabled");
      nextSelect.querySelectorAll('option').forEach(option => {
        if (option.value === "") {
          nextSelectPlaceholder = option;
        }
      })
      nextSelectPlaceholder.textContent = chooseText;
      nextSelectPlaceholder.setAttribute("selected", true);

      // only show available options on next select | n'afficher que les options disponibles sue le select suivant
      let selector = getSelector(index);
      let elements = document.querySelector('#sylius-variants-pricing').querySelectorAll(selector);
      let optionValues = [];
      elements.forEach(element=>{
        optionValues.push(element.getAttribute("data-" + nextSelect.dataset.option.toLowerCase()));
      })
      // array unique
      optionValues = optionValues.filter(function (value, index) {
        return optionValues.indexOf(value) === index;
      });
      nextSelect.querySelectorAll('option').forEach(option => {
        if (option.value === "" || optionValues.includes(option.value)) {
          option.style.display = "block";
        }
        else {
          option.style.display = "none";
        }
      })
    }
    else {
      // END
      productOptionsLoading = false; // cette variable sert à savoir si on doit afficher une rupture de stock ou un message affirmant qu'il faut choisir toutes les options avant d'ajouter au panier
      document.getElementById('step-by-step-info-msg').style.display = "none";
      document.getElementById('add-to-cart-btn').removeAttribute("disabled");
    }

  }

}

// get selector to find options
function getSelector(index) {
  let selector = "";

  for (let i = 0; i <= index; i++) {
    let select = document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]')[i];
    const option = select[select.selectedIndex].value;
    selector += `[data-${select.getAttribute('data-option')}="${option}"]`;
  }
  return selector;
}

// remove selected attribute from all select options | enlever l'attribut selected sur toutes les options du select
function deselectAllOptions(select) {
  select.querySelectorAll('option').forEach(option => {
    option.removeAttribute('selected');
  })
}

const handleProductVariantsChange = function handleProductVariantsChange() {
  document.querySelectorAll('[name="sylius_add_to_cart[cartItem][variant]"]').forEach((item) => {
    item.addEventListener('change', (e) => {
      const price = e.target.getAttribute('data-value');
      const originalPrice = e.target.getAttribute('data-original-value');
      const percentage = e.target.getAttribute('data-percentage');
      const stock = e.target.hasAttribute('data-stock');
      const variantId = e.target.getAttribute('data-variant-id');
      if (originalPrice !== '') {
        document.querySelector('[data-js-product-original-price]').innerHTML = originalPrice;
      } else {
        document.querySelector('[data-js-product-original-price]').innerHTML = '';
      }
      if (percentage !== null) {
        document.querySelector('[data-js-product-percentage]').innerHTML = `${percentage}`;
        document.querySelector('.picto-promo').style.display = 'inline-block';
      } else {
        if(document.querySelector('.picto-promo') !== null){
          document.querySelector('.picto-promo').style.display = 'none';
        }
        if(document.querySelector('[data-js-product-percentage]') !== null) {
          document.querySelector('[data-js-product-percentage]').innerHTML = '';
        }
      }
      if (stock) {
        $('.btn-panier-content').show();
        document.getElementById('enStock').classList.remove('d-none');
        document.getElementById('horsStock').classList.add('d-none');
        document.getElementById('restockingDate').classList.add('d-none');
        $('#availability-notifier').addClass("d-none");
      } else {
        $('.btn-panier-content').hide();

        document.getElementById('enStock').classList.add('d-none');
        document.getElementById('horsStock').classList.remove('d-none');
        document.getElementById('restockingDate').classList.remove('d-none');
        $('#availability-notifier').removeClass("d-none");
        document.forms['app_availability_notifier'].action="/shop/availability_notifier/add-availability-notifier?productVariantId="+variantId+"&template=@SyliusShop/Product/Show/_availability_notifier.html.twig";

      }

      document.querySelector('[data-js-product-price]').innerHTML = price;
    });
  });
};

const SyliusVariantsPrices = () => {
  const syliusVariantsPricing = document.getElementById('sylius-variants-pricing') || null;
  const syliusProductVariants = document.getElementById('sylius-product-variants') || null;

  if (syliusVariantsPricing) {
    handleProductOptionsChange();
  } else if (syliusProductVariants) {
    handleProductVariantsChange();
  }
};

document.addEventListener("DOMContentLoaded", function() {
  if (document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]').length === 2) {
    disableIncompatibleVariants(document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]')[0]);
  }
  else if (document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]').length > 2) {
    stepByStepProductOptions(document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]')[0], true);
  }
  // stock au chargement de la page
  if (document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]').length > 1) {
    let selector = '';
    document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]')[0].closest('#sylius-product-adding-to-cart').querySelectorAll('select[data-option]').forEach((element) => {
      const select = element;
      const option = select[select.selectedIndex].value;
      selector += `[data-${select.getAttribute('data-option')}="${option}"]`;
    });
    const detailsElement= document.querySelector('#sylius-variants-details').querySelector(selector);
    if (detailsElement) {
      let tracked = detailsElement.getAttribute('data-tracked');
      let onHand = detailsElement.getAttribute('data-onHand');
      let restockingDate = detailsElement.getAttribute('data-restockingDate');
      if((tracked == 1 && onHand < 1)  && (!productOptionsLoading)){
        $('#enStock').hide();
        $('.btn-panier-content').hide();
        $('#horsStock').show();
        if (restockingDate != '')
        {
          $('#restockingDate').show();
        }
        $('#availability-notifier').removeClass("d-none");
        // document.forms['app_availability_notifier'].action="/shop/availability_notifier/add-availability-notifier?productVariantId="+variantId+"&template=@SyliusShop/Product/Show/_availability_notifier.html.twig";
      }else{
        $('#enStock').show()
        $('#horsStock').hide();
        $('#restockingDate').hide();
        $('#variantQte').innerHTML=onHand;
        $('.btn-panier-content').show();

        $('#availability-notifier').addClass("d-none");
      }
    }


  }
})

export default SyliusVariantsPrices;
