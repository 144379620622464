import './jquery-multifile-2.2.2'
import '@popperjs/core';
import 'malihu-custom-scrollbar-plugin-3.1.7';
import 'ekko-lightbox';
import 'jquery-expander';
import 'jquery-zoom';

window.WOW = require('wowjs').WOW

$(document).on("click", '.qte-moins-art-fiche', function (event) {
  let inputQuantity = $(this).closest('.sylius-quantity-bit').find('input[type="number"]');
  let currentVal = parseInt(inputQuantity.val());
  if(currentVal > 1){
    inputQuantity.val(currentVal - 1);
  }
});
$(document).on("click", '.qte-plus-art-fiche', function (event) {
  let inputQuantity = $(this).closest('.sylius-quantity-bit').find('input[type="number"]');
  let currentVal = parseInt(inputQuantity.val());
  inputQuantity.val(currentVal + 1);
});

$(document).on('click', '[data-toggle="lightbox"]', function(event) {
  event.preventDefault();
  $(this).ekkoLightbox({ wrapping: false });
});
$('.scrollPerso').mCustomScrollbar({
  theme:"dark"
}) 
$('#tier_prices_tables').mCustomScrollbar({
  theme:"dark"
})

$(function () {
    $('[data-toggle="tooltip"]').tooltip()
  });
$('.expand-desc').expander({
	slicePoint: 150,
	expandEffect: 'slideDown',
	expandSpeed: 0,
	expandText: '<a href="#" class="text-underline  color-blue2-s d-inline-block font-weight-bold" title="">  > Lire la suite</a>',
	userCollapseText: '<a href="#" class="text-underline  color-blue2-s d-inline-block font-weight-bold" title=""> < Réduire </a>',
	collapseSpeed: 0,
	collapseEffect: 'slideUp'
  });
  $('.expand-me-mobile').expander({
	slicePoint: 150,
	expandEffect: 'slideDown',
	expandSpeed: 0,
	expandText: '<a href="#" class="text-underline  color-blue2-s d-inline-block font-weight-bold" title=""> > Lire la suite</a>',
	userCollapseText: '<a href="#" class=" text-underline color-blue2-s d-inline-block font-weight-bold" title=""> < Réduire </a>',
	collapseSpeed: 0,
	collapseEffect: 'slideUp'
  });
  




$(document).ready(function() {
/*************video modal product*** */
var $videoSrc;  
$('.video-btn').click(function() {
    $videoSrc = $(this).data( "src" );
	$('#videoModal').on('shown.bs.modal', function (e) { 
		$("#video").attr('src',$videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0");
	})
		  
	$('#videoModal').on('hide.bs.modal', function (e) {
		$("#video").attr('src',$videoSrc); 
	}) 
	
});

	/*************shpw< more resilt listpoint*** */
	$(document).on('click','#loadMore',function(e){
		e.preventDefault();
		$("#accordion").children('.accordion-bit:hidden').slice(0, 4).slideDown();
		if ( $("#accordion").children('.accordion-bit:last-child').css('display') =='block') {
		  $("#loadMore").fadeOut('slow');
		}
		$('html,body').animate({
		  scrollTop: $(this).offset().top
		}, 1500);
	  });
	$(".articleBit").hover(function(){
		$(this).parent().toggleClass('zindex'); 
		$(this).parent().prev('div').toggleClass('zindex_before'); 
	});

 /* $(".custom-file-input").each(function(){
  	$(this).next(".custom-file-label").text('Ajouter des pièces jointes');
  });*/
  $(".custom-file-input").change(function(){
    $(this).next(".custom-file-label").text(this.files[0].name);
	if ( $(this).next(".custom-file-label").parent().parent().next('.bloc-input-file-txt').find('#file-name-load').length>0){
		$(this).next(".custom-file-label").parent().parent().next('.bloc-input-file-txt').find('#file-name-load').text(this.files[0].name)
	}
  });
  $(".close-filtre-categ").click(function(){
	$('.cell-facette').fadeOut();
	$('body').removeClass('no-scrolling'); 
});

  /*********PANIER DEROULANT*****************/
  $('.li-panier').hover(function () {
		$(".panier-deroulant").toggleClass('focus');
	});

  $('.toggle-fav').click(function(e){
    e.preventDefault();
    var $this = $(this);

    if($this.hasClass("addfav-link")){
      //favoris animate
      $this.parent().find('.c1-a').addClass('animate1_me');
      $this.parent().find('.c2-a').addClass('animate2_me');
      $this.addClass('myfavoris');
      $this.removeClass('addfav-link');
    }else{
      $this.parent().find('.c1-a').removeClass('animate1_me');
      $this.parent().find('.c2-a').removeClass('animate2_me');
      $this.removeClass('myfavoris');
      $this.addClass('addfav-link');
    }

  });
  $(".click-search").click(function(e){
		e.preventDefault();
		$('.center-search').toggleClass('open-s');
		$('.center-search').parent().parent().toggleClass('open-parent');
	});
	$(".search-link").click(function(e){
		e.preventDefault();
		$('.center-search').toggleClass('open-sticky');
		$('.center-search').parent().parent().toggleClass('open-parent-sticky');
	});

  $('.addfav-link').click(function(){
    var id = $(this).attr('data-option');
    $.ajax({
        url: '/wishlist/add/' + id,
        type: 'POST',
        success:function(){
        },
        error:function(){

        }
    })
  });

  /*** Header sticky **/
  $(function () {
    var msie6 = $.browser == 'msie' && $.browser.version < 7;

    if (!msie6) {
      var top = $('.header-body').offset().top - parseFloat($('.header-body').css('margin-top').replace(/auto/, 0));
      $(window).scroll(function (event) {
        // what the y position of the scroll is
        var y = $(this).scrollTop();

        // whether that's below the form
        if (y > top) {
          // if so, ad the fixed class
          $('.header-body').addClass('headerFixed');
          $('.big-wrapper').addClass('padding-body');
        } else {
          // otherwise remove it
          $('.header-body').removeClass('headerFixed');
          $('.big-wrapper').removeClass('padding-body');
        }
      });
    }
  });

  /********SUB MENU OVERLAY******/
  var timeoutId;
  if ($(window).width() > 993) {
		
	var $navOverlay_ = $('<div class="menu-overlay">');
	$('.navItem').hover(function () {
			var $this = $(this);
		if ($this.find('.subMenu').length) {
			timeoutId = setTimeout(function(){
			$this.find('.navLink').addClass('active');

			$('.header-body').addClass('relative');
			$(document.body).append($navOverlay_);
			}, 250);
		}
	}, function () {
		
		
		if ($(this).find('.subMenu').length) {
			clearTimeout(timeoutId);
			$(this).find('.navLink').removeClass('active');
			
			if ($(document.body).find($navOverlay_)) {
				$(document.body).find($navOverlay_).remove();
				$('.header-body').removeClass('relative');
			}
		}
	});
 }
else
{
	$(".navItem").each(function () {
	  if ($(this).children('.navLink').next('.subMenu').length == 1) {
		  $(this).addClass('has-sub');
		  $(this).children(".navLink").click(function (e) {
			  e.preventDefault();
			  $(this).next('.subMenu').addClass('open');
			  $('.navigation-site').addClass('remove_mob_parent');
			  setTimeout(function () { 
				  $('body, html, .scroll-me-mobile_').scrollTop(0);
			  }, 100);

		  });
	  }
	  $('.back_menu').click(function (e) {
		  $('.subMenu').removeClass('open');
		  $('.navigation-site').removeClass('remove_mob_parent');
		  $('.subMenu').parent('.navItem').removeClass('active');
	  });
  });
}


  /** Scroll top Top **/
  $(window).scroll(function () {
    if ($(this).scrollTop() > 600) {
      $('.goToTop').fadeIn();
    } else {
      $('.goToTop').fadeOut();
    }
  });
  $('.goToTop').click(function () {
    $("html, body").animate({
      scrollTop: 0
    }, 600);
    return false;
  });

  /******* MENU TOOGLE *********/

  $('.toggle-menu').click(function(e){
	e.preventDefault();
	$('.navigation-site').toggleClass('open-mob');
	 $('.menu-mob-overlay').fadeToggle();
	  $('body').toggleClass('no-scrolling');

});
$('.close-menu-mob').click(function(e){
	e.preventDefault();
	$('.navigation-site').removeClass('open-mob');
	$('.menu-mob-overlay').fadeOut();
	 $('body').toggleClass('no-scrolling'); 

   $('.subMenu').removeClass('open');
   $('.navigation-site').removeClass('remove_mob_parent');
   $('.subMenu').parent('.navItem').removeClass('active');
});
$('.menu-mob-overlay').click(function(e){
	e.preventDefault();
   $('.navigation-site').removeClass('open-mob');
   $('.menu-mob-overlay').fadeOut();
   $('body').toggleClass('no-scrolling'); 
   $('.subMenu').removeClass('open');
   $('.navigation-site').removeClass('remove_mob_parent');
   $('.subMenu').parent('.navItem').removeClass('active');
});
  /********MENU FOOTER TOGGLE********/

  $('.toogle-menu-mob').click(function(){
    $('.other-menu').slideToggle();
  });

  $('.toggle-me').click(function(){
    var thisAnchor = $(this);
    var tab = thisAnchor.parents('.bloc-footer-menu');
    if(thisAnchor.next('.toggle-m-c ').css('display') !== 'none'){
      thisAnchor.removeClass('open');
      thisAnchor.next('.toggle-m-c').slideUp();
    }
    else{
      tab.find('.toggle-m-c').slideUp();
      tab.find('.toggle-me').removeClass('open');
      thisAnchor.siblings('.toggle-m-c ').slideDown();
      thisAnchor.addClass('open');}
  });
  $('.toggle-tabmob').click(function(){
    var thisAnchormob = $(this);
    var tabmob = thisAnchormob.parents('.tab-content');
    if(thisAnchormob.next('.toggle-content-tabmob').css('display') !== 'none'){
		thisAnchormob.removeClass('open');
		thisAnchormob.next('.toggle-content-tabmob').slideUp();
    }
    else{
		tabmob.find('.toggle-content-tabmob').slideUp();
		tabmob.find('.toggle-tabmob').removeClass('open');
		thisAnchormob.siblings('.toggle-content-tabmob').slideDown();
		thisAnchormob.addClass('open');}
  });

  $('.toggle-tab').click(function(){
	var thisAnchor_desk = $(this);
	var tab_desk = thisAnchor_desk.parents('.tab-content-parent');
	
	
	tab_desk.find('.toggle-content-tab').slideUp();
	
	if(thisAnchor_desk.next('.toggle-content-tab').css('display') !== 'none'){
		thisAnchor_desk.removeClass('active');
		thisAnchor_desk.next('.toggle-content-tab').slideUp();
	
	}
	else{
		tab_desk.children().find('.toggle-tab').removeClass('active');
		thisAnchor_desk.siblings('.toggle-content-tab').slideDown();
		thisAnchor_desk.addClass('active');	
	}

	
});	 

  $('.card-header').click(function () {
    var thisA = $(this);
    var tabA = thisA.parents('#accordion');

    if (thisA.next('.collapse').css('display') !== 'none') {
      thisA.removeClass('open');
      thisA.next('.collapse').slideUp();
    } else {
      tabA.find('.collapse').slideUp();
      tabA.find('.card-header').removeClass('open');
      thisA.siblings('.collapse').slideDown();
      thisA.addClass('open');
    }
  });

	/***WOW****/
	var wow = new WOW(
    {
      boxClass:     'wow',
      animateClass: 'animated',
      offset:       0,
      mobile:       false,
      live:         true,
      callback:     function(box) {
      },
      scrollContainer: null
      }
    )
    if($(window).width()>1024){
      wow.init();
    }

  /*
    Carousel
*/

});
function init_brandslide(){
	/*********BRAND CMS list************/
  
	var sliders_brand = [];
  
	$('.top-brand-cms').each(function (index, element) {
  
	  var next_swiper_m = $(this).children('.content-brand').find('.brand-next');
	  var prev_swiper_m = $(this).children('.content-brand').find('.brand-prev');
	  $(this).children('.content-brand').find('.swiper-brand').addClass('top-brand-cms-carou-' + index);
  
	  next_swiper_m.addClass('brand-next-' + index);
	  prev_swiper_m.addClass('brand-prev-' + index);
  
	  var slider_brand = new Swiper('.top-brand-cms-carou-' + index, {
		slidesPerView: 5,
		spaceBetween: 0,
		loop: false,
		autoplay: false,
		nextButton: '.brand-next-' + index,
		prevButton: '.brand-prev-' + index,
		pagination: '.swiper-pagination-brand',
		paginationClickable: true,
		speed: 600,
		breakpoints: {
			575: {
				slidesPerView: 2,
				spaceBetween: 0
			
			},
			767: {
				slidesPerView: 3,
				spaceBetween: 0
			
			},
			1200: {
				slidesPerView: 4,
				spaceBetween: 0,
			},

		}
	  });
	  sliders_brand.push(slider_brand);
	  var nbre_pag_m= $(this).children('.content-brand').children('.swiper-brand').children('.container-pagination').children('.swiper-pagination').find('.swiper-pagination-bullet').length;
	  if(nbre_pag_m<=1){
		$(this).children('.content-brand').children('.swiper-brand').addClass('just-one');
		$(this).children('.content-brand').children('.swiper-brand').find('swiper-wrapper').addClass('justify-content-center');
	  }
	  else{
		$(this).children('.content-brand').children('.swiper-brand').removeClass('just-one');
		$(this).children('.content-brand').children('.swiper-brand').find('swiper-wrapper').removeClass('justify-content-center');
  
	  }
	});
  
  }
function init_slide(){

	/********HOME SLIDER********/
	var swiperhome = new Swiper('.slider-home', {
	pagination: '.swiper-pagination-home',
	paginationClickable : true,
	nextButton: '.home-next',
	prevButton: '.home-prev',
	slidesPerView: 1,
	spaceBetween: 30,
	loop: false,
	autoplay:  2500,
	speed: 900,
	effect: 'fade'
});
	/* cpte slide */
	
	var nbre_pag= $('.slider-home').children('.container-pagination').children('.swiper-pagination').find('.swiper-pagination-bullet').length;
	if(nbre_pag<=1){
		$('.slider-home').addClass('just-one')
	}
	else{
		$('.slider-home').removeClass('just-one')
	}
	
	 
	/***********IMAGE ARTICLE***********/	
	
	var galleryThumbs = new Swiper('.gallery-thumbs-art', {
		spaceBetween: 10,
		slidesPerView: 3,
		nextButton: '.suppdesk-next',
		prevButton: '.suppdesk-prev',
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
		direction: 'vertical',
		
    });

    var galleryTop = new Swiper('.gallery-art-image', {
		spaceBetween: 10,
		slidesPerView: 1,
		nextButton: '.artimg-next',
		prevButton: '.artimg-prev',
		pagination: '.swiper-pagination-produit',
		paginationClickable : true,
		effect: 'fade',
    
  
    });	
  /*  galleryTop.on('slideChangeTransitionStart', function() {
		galleryThumbs.slideTo(galleryTop.activeIndex);
	});

	galleryThumbs.on('transitionStart', function(){
		galleryTop.slideTo(galleryThumbs.activeIndex);
	});*/
/*********TOP NEWS************/
    var swiper_news = new Swiper('.swiper-news', {
        slidesPerView: 5,
		slidesPerGroup : 5,
		spaceBetween: 0,
		pagination: '.swiper-pagination-news',
		paginationClickable : true,
		nextButton: '.news-next',
	    prevButton: '.news-prev',
		autoplay: false,
		speed: 600,
		breakpoints: {
			575: {
				slidesPerView: 2,
				spaceBetween: 0
			
			},
			767: {
				slidesPerView: 3,
				spaceBetween: 0
			
			},
			1200: {
				slidesPerView: 4,
				spaceBetween: 0,
			},

		}
    });
      var nbre_pags= $('.swiper-news').children('.container-pagination').children('.swiper-pagination').find('.swiper-pagination-bullet').length;
      if(nbre_pags<=1){
        $('.swiper-news').addClass('just-one')
      }
      else{
        $('.swiper-news').removeClass('just-one')
      }

	/*********TOP eco************/
    var swiper_eco = new Swiper('.swiper-eco', {
        slidesPerView: 5,
		slidesPerGroup : 5,
		spaceBetween: 0,
		pagination: '.swiper-pagination-eco',
		paginationClickable : true,
		nextButton: '.eco-next',
	    prevButton: '.eco-prev',
		
		autoplay: false,
		speed: 600,
		breakpoints: {
			575: {
				slidesPerView: 2,
				spaceBetween: 0
			
			},
			767: {
				slidesPerView: 3,
				spaceBetween: 0
			
			},
			1200: {
				slidesPerView: 4,
				spaceBetween: 0,
			},


		}
    });
      var nbre_pagp= $('.swiper-eco').children('.container-pagination').children('.swiper-pagination').find('.swiper-pagination-bullet').length;
      if(nbre_pagp<=1){
        $('.swiper-eco').addClass('just-one')
      }
      else{
        $('.swiper-eco').removeClass('just-one')
      }

	/*********TOP PROMO************/
    var swiper_promo = new Swiper('.swiper-pack', {
        slidesPerView: 5,
		slidesPerGroup : 5,
		spaceBetween: 0,
		pagination: '.swiper-pagination-pack',
		paginationClickable : true,
		nextButton: '.pack-next',
	    prevButton: '.pack-prev',
		autoplay: false,
		speed: 600,
		breakpoints: {
			575: {
				slidesPerView: 2,
				spaceBetween: 0
			
			},
			767: {
				slidesPerView: 3,
				spaceBetween: 0
			
			},
			1200: {
				slidesPerView: 4,
				spaceBetween: 0,
			},

		}
    });
      var nbre_pagpr= $('.swiper-pack').children('.container-pagination').children('.swiper-pagination').find('.swiper-pagination-bullet').length;
      if(nbre_pagpr<=1){
        $('.swiper-pack').addClass('just-one')
      }
      else{
        $('.swiper-pack').removeClass('just-one')
      }
	  
	  /*********INDEx CATEGORRY************/
    var swiper_category = new Swiper('.swiper-category', {
        slidesPerView: 4,
		slidesPerGroup : 1,
		spaceBetween: 0,
		nextButton: '.category-next',
		prevButton: '.category-prev',
		pagination:'.swiper-pagination-category',
		paginationClickable : true,
		autoplay: false,
		speed: 600,
		breakpoints: {
			576: {
			slidesPerView: 2,
			spaceBetween: 10
			},
			1580: {
				slidesPerView: 3,
				spaceBetween: 0
			}

		}
    });
	  /*********INDEx CATEGORRY************/
	  var swiper_category2 = new Swiper('.swiper-category2', {
        slidesPerView: 3,
		slidesPerGroup : 3,
		spaceBetween: 0,
		nextButton: '.category2-next',
		prevButton: '.category2-prev',
		pagination:'.swiper-pagination-category2',
		paginationClickable : true,
		autoplay: false,
		speed: 600,
		breakpoints: {
			
			576: {
				slidesPerView: 2,
				spaceBetween: 10
			
			},
			

		}
    });

	  /*********ssCATEGORRY************/
	  var swiper_sscategory = new Swiper('.swiper-sscategory', {
		slidesPerView: 3,
		slidesPerGroup : 3,
		spaceBetween: 0,
		nextButton: '.sscategory-next',
		prevButton: '.sscategory-prev',
		pagination:'.swiper-pagination-sscategory',
		paginationClickable : true,
		autoplay: false,
		speed: 600,
		breakpoints: {
			
			576: {
				slidesPerView: 2,
				spaceBetween: 10
			
			}
		
		}
    });
	/********HOME SLIDER COLLECTION********/
	var swipercollectionindex = new Swiper('.slider-collection-index', {
	    pagination:'.swiper-pagination-collectionindex',
		paginationClickable : true,
		nextButton: '.collection-index-next', 
	    prevButton: '.collection-index-prev',
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
		autoplay:2500,
		speed: 900,
    });
	/* cpte slide */
	
	var nbre_pagcoll= $('.slider-collection-index').children('.swiper-pagination').find('.swiper-pagination-bullet').length;
	if(nbre_pagcoll<=1){
		$('.slider-collection-index').addClass('just-one')
	}
	else{
		$('.slider-collection-index').removeClass('just-one')
	}
/*********TOP conseil************/
var swiper_conseil = new Swiper('.swiper-conseil', {
	slidesPerView: 5,
	slidesPerGroup : 5,
	spaceBetween: 0,
	pagination: '.swiper-pagination-conseil',
	paginationClickable : true,
	nextButton: '.conseil-next',
	prevButton: '.conseil-prev',
	autoplay: false,
	speed: 600,
	breakpoints: {
		575: {
			slidesPerView: 2,
			spaceBetween: 0
		
		},
		767: {
			slidesPerView: 3,
			spaceBetween: 0
		
		},
		980: {
			slidesPerView: 4,
			spaceBetween: 0,
		},

	}
});
  var nbre_pagcons= $('.swiper-conseil').children('.container-pagination').children('.swiper-pagination').find('.swiper-pagination-bullet').length;
  if(nbre_pagcons<=1){
	$('.swiper-conseil').addClass('just-one')
  }
  else{
	$('.swiper-conseil').removeClass('just-one')
  }
/********* ARTICLE SIMILAIRE************/
var swiper_collection2 = new Swiper('.swiper-collection2', {
	slidesPerView: 5,
	slidesPerGroup : 5,
	spaceBetween: 0,
	pagination: '.swiper-pagination-collection2',
	paginationClickable : true,
	nextButton: '.collection2-next',
	prevButton: '.collection2-prev',
	autoplay: false,
	speed: 600,
	breakpoints: {
		575: {
			slidesPerView: 2,
			spaceBetween: 0
		
		},
		767: {
			slidesPerView: 3,
			spaceBetween: 0
		
		},
		980: {
			slidesPerView: 4,
			spaceBetween: 0,
		},

	}
});
  var nbre_pagcoll2= $('.swiper-collection2').children('.container-pagination').children('.swiper-pagination').find('.swiper-pagination-bullet').length;
  if(nbre_pagcoll2<=1){
	$('.swiper-collection2').addClass('just-one')
  }
  else{
	$('.swiper-collection2').removeClass('just-one')
  }

   /*********TOP conseil************/
var swiper_visited = new Swiper('.swiper-visited', {
	slidesPerView: 7,
	slidesPerGroup : 1,
	spaceBetween: 0,
	pagination: '.swiper-pagination-visited',
	paginationClickable : true,
	nextButton: '.visited-next',
	prevButton: '.visited-prev',
	autoplay: false,
	speed: 600,
	breakpoints: {
		575: {
			slidesPerView: 2,
			spaceBetween: 0
		
		},
		767: {
			slidesPerView: 3,
			spaceBetween: 0
		
		},
		980: {
			slidesPerView: 4,
			spaceBetween: 0,
		},
		1200: {
			slidesPerView: 5,
			spaceBetween: 0,
		},

	}
});
  var nbre_pagvisited= $('.swiper-visited').children('.container-pagination').children('.swiper-pagination').find('.swiper-pagination-bullet').length;
  if(nbre_pagvisited<=1){
	$('.swiper-visited').addClass('just-one')
  }
  else{
	$('.swiper-visited').removeClass('just-one')
  }
}

window.addEventListener("resize", function(){
init_slide();
init_brandslide()
});
  
$(document).ready(function(e) {
   init_slide();
   init_brandslide()
});






