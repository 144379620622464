/*
 * This file is part of the Sylius package.
 *
 * (c) Paweł Jędrzejewski
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/* eslint-env browser */

import axios from 'axios';
import serialize from 'form-serialize';
import 'bootstrap/js/dist/modal';
const SyliusAddToCart = (el) => {

  const element = el;
  const url = element.getAttribute('action');
  const redirectUrl = element.getAttribute('data-redirect');
  const isRedirect = element.getAttribute('data-is-redirect');
  const validationElement = element.querySelector('[data-js-add-to-cart="error"]');

  element.addEventListener('submit', (e) => {

    const request = axios.post(url, serialize(element));

    e.preventDefault();

    request.then((response) => {

      if (isRedirect == 'true')
      {
        window.location.replace(redirectUrl);
        return false;
      }

      let name = response.data.variantName;
      let path = response.data.source_path;
      let options = response.data.options;
      let quantity = response.data.quantity;
      let price = response.data.price;

      // si pas d'image, afficher un "no image" sinon ça génèrera une erreur (pas bloquante)
      var http = new XMLHttpRequest();
      http.open('HEAD', path, false);
      http.send();
      console.log(http.status)

      if (http.status === 404 || http.status === 500) {
        if (path.indexOf('media/cache') !== -1) {
          path = path.substring(0, path.indexOf('media/cache')) + "images/spacer.png";
          console.log(path)
        }
      }

      validationElement.classList.add('d-none');
      element.classList.remove('loading');
      $('#modal-addProduct-image')
        .empty();
      $('#modal-addProduct-name')
        .empty();
      $('#modal-addProduct-options')
        .empty();
      $('#modal-addProduct-quantity')
        .empty();
      $('#modal-addProduct-price')
        .empty();

      $('#modal-addProduct-image')
        .append(
          '<img src="' + path + '"/>'
        );
      $('#modal-addProduct-name')
        .append(
          '<span>' + name + '</span>'
        );
      $('#modal-addProduct-options')
        .append(
          '<span>' + options + '</span>'
        );
      $('#modal-addProduct-quantity')
        .append(
          '<span>' + quantity + '</span>'
        );
      $('#modal-addProduct-price')
        .append(
          '<span>' + price + '</span>'
        );
      $('#addToCartModal')
        .modal('show');

      $.ajax({
        url: '/app_summary',
        type: "GET",
        success: function (data) {
          $("#contenuPanierCommunWidget")
            .html(data);
        },
        error: function () {
          console.log("error popup");
        }
      });

      $.ajax({
        url: '/app_summary_popup',
        type: "GET",
        success: function (data) {
          $("#contenuPanierCommunPopup")
            .html(data);
        },
        error: function () {
          console.log("error popup");
        }
      });

    });

    request.catch((error) => {
      validationElement.classList.remove('d-none');
      let validationMessage = '';

      Object.entries(error.response.data).forEach(([, message]) => {
        validationMessage += message.errors;
      });

      validationElement.innerHTML = validationMessage;
      element.classList.remove('loading');
    });
  });
};

export default SyliusAddToCart;



