import $ from 'jquery';
$(function(){
  let ajaxCall;
  init_filter();
  $('body').on('submit', 'form[name="filters-form"]', function(event){
    event.preventDefault();

    const url = $(this).attr('action');
    const data = $(this).serialize();


    if(ajaxCall != undefined){
      ajaxCall.abort();
    }
    ajaxCall = $.ajax({
      type:"GET",
      data: data,
      url: url,
      success: function(response){
        if(typeof $(response).find('.no-result').html() !== 'undefined' ){
          $(".liste-articles").html($(response).find('.no-result').html());
        }else{
          $(".page-categorie-content").html($(response).find('.page-categorie-content').html());
        }
        init_filter();
      }
    })
  })

//click on list of filter to delete it
  $('body').on('click', '.delete-filter', function(){
    const filter = $(this).attr('data-filter');
    $('#'+filter).prop('checked', false);
    $('.mbiz-sylius-search-filters form[name="filters-form"]').submit();
  })

  // ------------INSTANT SEARCH-------------

  function instantSearch() {
    $("#monsieurbiz_searchplugin_search_query").attr('name', 'query');

    event.preventDefault();
    let form = $('form[name="monsieurbiz_searchplugin_search"]');
    const data = form.serialize();

    if (ajaxCall != undefined) {
      ajaxCall.abort();
    }
    ajaxCall = $.ajax({
      type: "GET",
      data: data,
      url: '/instant',
      success: function (response) {
        $(".autocomplete-results").html($(response));
        $("#monsieurbiz_searchplugin_search_query").focus();

        if($('.left-search').closest('.openable').find('input:checked').length > 0){
          $(this).closest('.openable').addClass('opened');
        }
      }
    })
    $(".autocomplete-results").css('display:block');

  }

  $(".left-search").on("click", ".instant-search",function(){
    instantSearch()
  } );

  $('.left-search input[type="number"]').on('change', function () {
    $(this).trigger('keyup');
  })

  $('.left-search').on('click', '#searchClose', function () {
    $("#autocomplete-results").css("display", "none");
  });
  $('.left-search').on('click', '.btn-see-more', function (event) {
    $(this).closest('.openable').addClass('opened');
  });
  $('.left-search').on('click', '.btn-see-less',function (event) {
    $(this).closest('.openable').removeClass('opened');
  });

  $('.left-search').on('click', '#resetFilters',function (event) {
    $('.left-search').find('*').attr('checked', false);
    instantSearch()
  });


  // Ajout de class pour l'intégration de l'instant search

  const divInstant = document.getElementById('autocomplete-results');

  let observer = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutationRecord) {
      if (divInstant.style.display === 'none') {
        $('.header-body').removeClass('show-instant-result')
      } else {
        $('.header-body').addClass('show-instant-result')
      }
    });
  });

  observer.observe(divInstant, {attributes: true, attributeFilter: ['style']});


  $('.show-search').click(function(e){
    e.preventDefault();
    $('.center-search').toggleClass('search-open');

  });
  $('.close-search').click(function(e){
    e.preventDefault();
    $('.center-search').removeClass('search-open');

  });
  function init_filter() {
    $('.label').click(function(){
      var thisAnchor2 = $(this);
      var tab2 = thisAnchor2.parents('.content-fitre-categ');
      tab2.find('.filter-values').slideUp();
      tab2.children().children('.item').children('.filter-item').find('.label').removeClass('active');

      if(thisAnchor2.next('.filter-values').css('display') !== 'none'){
        thisAnchor2.removeClass('active');
        thisAnchor2.removeClass('has-selection');
        thisAnchor2.next('.filter-values').slideUp();
      }
      else{
        tab2.children().children('.item').children('.filter-item').find('.label').removeClass('active');
        tab2.children().children('.item').children('.filter-item').find('.label').removeClass('has-selection');
        thisAnchor2.siblings('.filter-values').slideDown();
        thisAnchor2.addClass('active');

      }
    });
  }
  $(".mobile-label-filtre").click(function(){
    $(this).toggleClass("active");
    $(this).next('.cell-facette').fadeIn();
    $('body').toggleClass('no-scrolling');
  });
  $(".close-filtre-categ").click(function(){
    $('.cell-facette').fadeOut();
    $('body').removeClass('no-scrolling');
  });

});
