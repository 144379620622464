import $ from 'jquery';

document.addEventListener("DOMContentLoaded", function() {
  let cookieConsent = document.querySelector('.ch-cookie-consent');
  let cookieConsentForm = document.querySelector('.ch-cookie-consent__form');
  let cookieConsentFormBtn = document.querySelectorAll('.ch-cookie-consent__btn');

  // If cookie consent is direct child of body, assume it should be placed on top of the site pushing down the rest of the website
  if (cookieConsent && cookieConsent.parentNode.nodeName === 'BODY') {
      if (cookieConsent.classList.contains('ch-cookie-consent--top')) {
          document.body.style.marginTop = cookieConsent.offsetHeight + 'px';

          cookieConsent.style.position = 'absolute';
          cookieConsent.style.top = '0';
          cookieConsent.style.left = '0';
      } else {
          document.body.style.marginBottom = cookieConsent.offsetHeight + 'px';

          cookieConsent.style.position = 'fixed';
          cookieConsent.style.bottom = '0';
          cookieConsent.style.left = '0';
      }
  }

  if (cookieConsentForm) {
      // Submit form via ajax
      for (let i = 0; i < cookieConsentFormBtn.length; i++) {
          let btn = cookieConsentFormBtn[i];
          btn.addEventListener('click', function (event) {
              event.preventDefault();

              let formAction = cookieConsentForm.action ? cookieConsentForm.action : location.href;
              let xhr = new XMLHttpRequest();

              xhr.onload = function () {
                  if (xhr.status >= 200 && xhr.status < 300) {
                      cookieConsent.style.display = 'none';
                      let buttonEvent = new CustomEvent('cookie-consent-form-submit-successful', {
                          detail: event.target
                      });
                      document.dispatchEvent(buttonEvent);
                  }
              };
              xhr.open('POST', formAction);
              xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

              if ($(this).attr('id') == 'cookie_consent_use_only_functional_cookies')
                xhr.send(serializeForm(cookieConsentForm, event.target, true)); // Passe toutes les valeurs sur FALSE
              else
                xhr.send(serializeForm(cookieConsentForm, event.target, false));

              // Clear all styles from body to prevent the white margin at the end of the page
              document.body.style.marginBottom = null;
              document.body.style.marginTop  = null;
          }, false);
      }
  }

  $('.ch-cookie-consent__toggle-details-show').click(function(e) {
    e.preventDefault();
    $('.ch-cookie-consent__category-group').show();
    $('.ch-cookie-consent__toggle-details-hide').show();
    $(this).hide();
  });

  $('.ch-cookie-consent__toggle-details-hide').click(function(e)
  {
    e.preventDefault();
    $('.ch-cookie-consent__category-group').hide();
    $('.ch-cookie-consent__toggle-details-show').show();
    $(this).hide();
  });

  $('.cookie-management').click(function(e)
  {
    e.preventDefault();
    $('.ch-cookie-consent').removeClass('d-none').css('display', '');
    $('.ch-cookie-consent__toggle-details-show').hide();
    $('.ch-cookie-consent__toggle-details-hide').show();
    $('.ch-cookie-consent__category-group').show();
    return false;
  });
});

function serializeForm(form, clickedButton, disableAll) {
  let serialized = [];

  for (let i = 0; i < form.elements.length; i++) {
      let field = form.elements[i];

      if ((field.type !== 'checkbox' && field.type !== 'radio' && field.type !== 'button') || field.checked) {
        if ((disableAll == true) && (field.type == 'radio'))
          serialized.push(encodeURIComponent(field.name) + "=false");
        else
          serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value));
      }
  }

  serialized.push(encodeURIComponent(clickedButton.getAttribute('name')) + "=");

  return serialized.join('&');
}

if ( typeof window.CustomEvent !== "function" ) {
  function CustomEvent(event, params) {
      params = params || {bubbles: false, cancelable: false, detail: undefined};
      let evt = document.createEvent('CustomEvent');
      evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
      return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
}
