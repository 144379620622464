// Main scripts file
const $ = require('jquery');
global.$ = global.jQuery = $;
require('bootstrap');
import './js/index';


import './js/script';
import './js/swiper';
import './js/product_search';
import './js/cookie_consent';

import moment from 'moment';
window.moment = moment;
//import 'bootstrap-daterangepicker';

//import 'bootstrap-datepicker';
//import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.fr';

// Main styles file
import './scss/index.scss';
// Images
import './media/sylius-logo.png';
// Font awesome icons
import './js/fontawesome';

import './css/swiper.css';
import './css/custom_styles.css';
import './css/fonts.css';
import './css/animate.css';
import './css/fontello.css';
import './css/css_mobile.css';



